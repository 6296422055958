* {
    color: #1C1C1C
}

body {
    background-color: #FFFFFF;
    margin: 0;
    font-family: 'Heebo', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.svg-container {
    display: flex;
    flex-shrink: 0;
}

.tao-table-container {
    min-width: 600px;
    padding: 16px;
}

.tao-table-header {
    width: 100%;
}

.tao-table-header th {
    width: 150px;
}

.tao-table {
    border-collapse: separate;
    border-spacing: 0 16px;
    width: 100%;
}

.tao-table tr {
    background-color: white;
    cursor: pointer;
    box-shadow: rgb(145 158 171 / 20%) 0px 0px 2px 0px, rgb(145 158 171 / 12%) 0px 12px 24px -4px;
}

.tao-table tr:hover {
    background-color: #F9D10030;
}

.tao-table td {
    width: 150px;
    padding: 8px 16px;
}

.tao-table tr td {
    border-right: 0;
    border-left: 0;
}

.tao-table tr td:first-child {
    border-radius: 8px 0 0 8px;
}

.tao-table tr td:last-child {
    border-radius: 0 8px 8px 0;
}
