html {
    /*scroll-behavior: smooth;*/
}

html, body, main, .root {
    height: 100%;
}

/***** #Loading *****/
.lds-ellipsis {
    position: relative;
    width: 64px;
    height: 16px;
}

.lds-ellipsis div {
    position: absolute;
    border-radius: 50%;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.lds-ellipsis div:nth-child(1) {
    animation: lds-ellipsis1 0.6s infinite;
}

.lds-ellipsis div:nth-child(2) {
    animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(3) {
    left: 24px;
    animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(4) {
    left: 48px;
    animation: lds-ellipsis3 0.6s infinite;
}

@keyframes lds-ellipsis1 {
    0% {
        transform: scale(0);
    }
    100% {
        transform: scale(1);
    }
}

@keyframes lds-ellipsis3 {
    0% {
        transform: scale(1);
    }
    100% {
        transform: scale(0);
    }
}

@keyframes lds-ellipsis2 {
    0% {
        transform: translate(0, 0);
    }
    100% {
        transform: translate(24px, 0);
    }
}

/***** Loading# *****/

.feedback-card {
    box-shadow: 7px 5px 13px 2px rgb(0 0 0 / 21%);
    -webkit-box-shadow: 7px 5px 13px 2px rgb(0 0 0 / 21%);
    -moz-box-shadow: 7px 5px 13px 2px rgb(0 0 0 / 21%);
}